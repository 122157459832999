import React from "react";

import { ArrowLeftIcon } from "@chakra-ui/icons";
import { Box, Button, ButtonGroup, Center, Container, Link, Text } from "@chakra-ui/react";
import NextLink from "next/link";
import { useRouter } from "next/router";

import AlertCircleIcon from "@/icons/AlertCircleIcon";

const _Error = () => {
  const router = useRouter();
  return (
    <Container h="lg">
      <Center
        flexDirection="column"
        alignItems="center"
        maxW="xl"
        m="auto"
        h="full"
      >
        <Box
          boxSize="60px"
          backgroundColor="#F8FAFF"
          pt="10px"
          pl="10px"
          borderRadius="50%"
        >
          <Box
            boxSize="40px"
            backgroundColor="#E7EDFF"
            pt="6px"
            pl="6px"
            borderRadius="50%"
          >
            <AlertCircleIcon
              color="blue.600"
              boxSize="28px"
            />
          </Box>
        </Box>
        <Text
          fontWeight="semibold"
          fontSize={{ base: "36px", md: "60px" }}
          lineHeight={{ base: "44px", md: "72px" }}
          letterSpacing="1%"
          color="gray.900"
          textAlign="center"
          mb={{ base: 8, md: 12 }}
        >
          Page not found
        </Text>
        <Text
          fontWeight="400"
          fontSize={{ base: "lg", md: "xl" }}
          lineHeight={{ base: "28px", md: "30px" }}
          color="gray.600"
          textAlign="center"
        >
          The page you are looking for doesn't exist. Here are some helpful links:
        </Text>
        <ButtonGroup
          size="lg"
          w={{ base: "full", md: "fit-content" }}
          mt={{ base: 8, md: 12 }}
          flexDirection={{ base: "column", md: "row" }}
          gap={3}
        >
          <Button
            variant="outline"
            w="full"
            onClick={() => router.back()}
            leftIcon={<ArrowLeftIcon />}
          >
            Go back
          </Button>

          <NextLink
            href="/signin"
            passHref
          >
            <Button
              colorScheme="blue"
              w="full"
              marginLeft="0px !important"
            >
              Sign in
            </Button>
          </NextLink>
        </ButtonGroup>
      </Center>
    </Container>
  );
};

export default _Error;
