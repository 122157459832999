import React from "react";

import { Icon, IconProps } from "@chakra-ui/react";

const AlertCircleIcon = (props: IconProps) => {
  return (
    <Icon
      width="22px"
      height="22px"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11 7V11M11 15H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
  );
};

export default AlertCircleIcon;
